<template>
    <div class="max-w-[1364px] mx-auto mt-[29px]">
        <div class="flex flex-row items-center">
            <div>
                <a class="text-[15px] font-medium text-[#989898] td
                            hover:text-[#989898]" href="javascript:void(0);"
                >
                    {{ __('bosh_sahifa') }}
                </a>
            </div>
            <div class="flex items-center mx-[13px]">
                <img alt="" src="@/assets/chevron-right-gray.svg">
            </div>
            <div>
                <div class="text-[15px] text-[#000] font-medium td">
                    {{ __('buyurtma_berish') }}
                </div>
            </div>
        </div>
        <div class="my-[45px] text-[#000] font-medium text-[25px]">
            {{ __('buyurtmani_shakllantirish') }}
        </div>

        <form>
            <HeaderCheck class="header-underline-date">
                <template #name>
                    {{ __('your_inform') }}
                </template>
            </HeaderCheck>

            <div class="uk-flex mt-[41.4px]">
                <div>
                    <div class="text-[#000] text-[15px] font-medium">
                        {{ __('phone') }}
                    </div>
                    <div class="w-[402px] p-[18px] h-[56px] flex flex-row
                     border border-[#C3B2FF] rounded-[8px] mt-[17px] relative"
                         :class="{'border-red-600' : form.errors.phone, 'border border-[#C3B2FF]' : !form.errors.phone}"
                    >
                        <div class="text-[#000] text-[16px] font-medium">
                            (+998)
                        </div>
                        <div class="ml-[3px]">
                            <input
                                    ref="phone"
                                    v-model="tempPhone"
                                    class="focus:outline-0 w-[320px] text-[#000] font-medium"
                                    type="text"
                                    placeholder="-- --- -- --"
                            >
                            <!--                            <input ref="phone"-->
                            <!--                                   -->
                            <!--                                   v-mask="'(00)000-00-00'"-->
                            <!--                                   placeholder="&#45;&#45; -&#45;&#45; &#45;&#45; &#45;&#45;"-->
                            <!--                                   type="text"-->
                            <!--                                   class="focus:outline-0 w-full text-[#000] font-medium mr-[180px]"/>-->
                        </div>
                    </div>
                </div>
                <div class="ml-[35px]">
                    <div class="text-[#000] text-[15px] font-medium">
                        {{ __("fish") }}
                    </div>
                    <div class="w-[612px] p-[18px] h-[56px] flex flex-row
                     border  rounded-[8px] mt-[17px] relative"
                         :class="{'border-red-600' : form.errors.first_name, 'border border-[#C3B2FF]' : !form.errors.first_name}"
                    >
                        <div class="ml-[3px]">
                            <input ref="first_name"
                                   v-model="form.first_name"
                                   type="text"
                                   class=" focus:outline-0 block w-[580px] text-[#000] font-medium"
                            >
                        </div>
                    </div>
                </div>
            </div>

            <HeaderCheck class="header-underline-location mt-[60.4px] mb-[41.4px]">
                <template #name>
                    {{ __('location_a') }}
                </template>
            </HeaderCheck>
            <div class="uk-flex mb-[41.2px]">
                <div>
                    <div class="text-[#000] text-[15px] font-medium mb-[17px]">
                        {{ __('viloyat') }}
                    </div>
                    <div>
                        <div
                                :class="[openRegion ? 'relative uk-inline opened' : 'relative uk-inline', form.errors.region ? 'error' : '']">
                            <select2 :options="regions"
                                     @focusin="openRegion=true"
                                     @focusout="openRegion=false"
                                     @select="regionSelectEvent($event)"
                                     v-model="form.regions_id"
                            >
                            </select2>
                            <img src="@/assets/chevron-down.svg" alt="" class="absolute"
                                 style="top: 24px; right: 24px;">
                        </div>
                    </div>
                </div>
                <div class="ml-[35px]">
                    <div class="text-[#000] text-[15px] font-medium mb-[17px]">
                        {{ __('tuman') }}
                    </div>
                    <div>
                        <div
                                :class="[openCity ? 'relative uk-inline opened' : 'relative uk-inline', form.errors.city ? 'error' : '']">
                            <Select2 :options="areas"
                                     @select="areaSelectEvent($event)"
                                     @focusin="openCity=true"
                                     @focusout="openCity=false"
                                     v-model="form.areas_id"
                            >
                            </Select2>
                            <img src="@/assets/chevron-down.svg" alt="" class="absolute"
                                 style="top: 24px; right: 24px;">
                        </div>
                    </div>
                </div>
            </div>
            <div class="uk-flex mb-[50px]">
                <div>
                    <div class="text-[#000] text-[15px] font-medium">
                        {{ __('manzil') }}
                    </div>
                    <input type="text"
                           class="px-[18px] input py-[18px] bg-[#fff] w-[839px] h-[56px]
                          focus:outline-0 border border-[#C3B2FF] rounded-[8px]
                          mt-[17px] text-[#000] "
                           :placeholder='__("example")'
                           v-model="form.address"
                           ref="address"
                           :class="{'border-red-600' : form.errors.address, 'border border-[#C3B2FF]' : !form.errors.address}"
                    >
                </div>

                <!-- <button type="submit" @click="submit"
                        class="bg-[#6848D2] rounded-[7px] text-[16px] text-[#fff] ml-[35px] mt-[39px]
                        cursor-pointer px-[10px] py-[18px] max-h-[56px] flex items-center"
                >
                    подтвердить
                </button> -->
            </div>
            <div class="uk-flex mb-[50px]">
                <div>
                    <div class="text-[#000] text-[15px] font-medium">
                        {{__("content")}}
                    </div>
                    <textarea
                            class="px-[18px] input py-[18px] bg-[#fff] w-[839px] h-[156px]
                        focus:outline-0 border border-[#C3B2FF] rounded-[8px]
                        mt-[17px] text-[#000] "
                        :placeholder='__("content")'
                            v-model="form.purpose"
                            :class="{'border-red-600' : form.errors.purpose, 'border border-[#C3B2FF]' : !form.errors.purpose}">
                    </textarea>
                </div>
            </div>

            <!--            <div class="uk-flex mb-[50px]">-->
            <!--                <div style="margin-top: 15px; color: black;" class="flex" v-if="formPostal.receiverCityId != null">-->
            <!--                    <input type="checkbox" id="checkbox" class="" v-model="checked">-->
            <!--                    <div class="block">-->
            <!--                        <label for="checkbox"-->
            <!--                               class="text-black font-semibold text-[16px] hover:cursor-pointer self-center block">-->
            <!--                            Bts pochta xizmatidan foydalanish-->
            <!--                        </label>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->


            <div class="mb-[60.8px] ">
                <HeaderCheck class="header-underline-order mb-[48.7px]">
                    <template #name>
                        {{ __('your_order') }}
                    </template>
                </HeaderCheck>
                <div class="uk-grid order-bottom-line">
                    <div class="uk-overflow-auto w-full">
                        <table class="uk-table uk-table-hover uk-table-middle uk-table-divider">
                            <thead>
                            <tr>
                                <th class="" style="color: black !important; font-weight: bold; font-size: 18px">
                                    {{__("name")}}
                                </th>
                                <th class="" style="color: black !important; font-weight: bold; font-size: 18px">
                                    {{__('nomenclature')}}
                                </th>
                                <th class="uk-table-shrink uk-text-nowrap"
                                    style="color: black !important; font-weight: bold; font-size: 18px">
                                    {{__('masshtab')}}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in items" :key="index">
                                <td class="text-[#000000] text-[16px] font-medium leading-[19px] self-center">
                                    <div class="bg-[#F7F7FF] rounded-[8px] py-[13px] px-[25px] mr-[26px]"
                                         style="float: left">
                                        <img class="uk-preserve-width" src="@/assets/file_storage.png" width="35"
                                             height="35" alt="file">
                                    </div>
                                    <p class="mt-4">{{item.name}}</p>
                                </td>
                                <td class="text-[#000000] text-[16px] font-medium leading-[19px] self-center">
                                    {{item.nomenclature}}
                                </td>
                                <td class="text-[#000000] text-[16px] font-medium leading-[19px] self-center">
                                    {{item.masshtab}}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <!--                    <div class="uk-navbar-left">-->
                    <!--                        <div class="text-[#000000] text-[18px] w-[319px] font-semibold leading-[22px]">-->
                    <!--                            {{ __('mahsulot') }}-->
                    <!--                        </div>-->

                    <!--                    </div>-->
                    <!--                    <div class="uk-navbar-right">-->
                    <!--                        <ul class="flex space-x-36 text-[#000000] text-[18px] font-semibold leading-[22px] mr-[75px]">-->
                    <!--                            &lt;!&ndash;                            <li>&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                                {{ __('count') }}&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                            </li>&ndash;&gt;-->
                    <!--                            <li class="w-[319px]">-->
                    <!--                                {{ __('nomenclature') }}-->
                    <!--                            </li>-->
                    <!--                            <li>-->
                    <!--                                {{ __('masshtab') }}-->
                    <!--                            </li>-->
                    <!--                            &lt;!&ndash; <li>-->
                    <!--                                Доставка-->
                    <!--                            </li> &ndash;&gt;-->
                    <!--                            &lt;!&ndash;                            <li>&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                                {{ __('all_of_them') }}&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                            </li>&ndash;&gt;-->
                    <!--                        </ul>-->
                    <!--                    </div>-->
                </div>
                <!--                <div v-for="(item, index) in items" :key="index" class="flex mt-[36px] order-bottom-line-a">-->
                <!--                    <div class="bg-[#F7F7FF] rounded-[8px] py-[13px] px-[25px] mr-[26px]">-->
                <!--                        &lt;!&ndash;                        <img v-if="product.images.length > 0" :src="product.images[0].image_url"&ndash;&gt;-->
                <!--                        &lt;!&ndash;                             class="w-[55px] h-[74px]"&ndash;&gt;-->
                <!--                        &lt;!&ndash;                             alt="">&ndash;&gt;-->
                <!--                        <img class="w-[33px] h-[33px]" src="@/assets/file_storage.png" alt="">-->
                <!--                    </div>-->
                <!--                    <div class="text-[#000000] text-[16px] font-medium leading-[19px] self-center w-[319px] mr-[301px] order-bottom-line-b">-->
                <!--                        {{item.name}}-->
                <!--                    </div>-->
                <!--                    <div class="text-[#000000] text-[20px] font-medium self-center mr-[99px] w-[214px] order-bottom-line-d">-->
                <!--                        {{ item.nomenclature }}-->
                <!--                    </div>-->
                <!--                    <div class="text-[#000000] text-[20px] font-medium self-center mr-[99px] w-[214px] ">-->
                <!--                        {{ item.masshtab }}-->
                <!--                    </div>-->
                <!--                    &lt;!&ndash;                    <div class="text-[#6C4BD8] text-[20px] font-semibold self-center">&ndash;&gt;-->
                <!--                    &lt;!&ndash;                        {{ number_format (item.count * item.price, ' ', ' ') }} {{ __('som') }}&ndash;&gt;-->
                <!--                    &lt;!&ndash;                    </div>&ndash;&gt;-->
                <!--                </div>-->


                <!--                <div class="justify-content-right">-->
                <!--                    <div class="flex justify-end mt-[40px] self-center order-right-line">-->
                <!--                        <div class="text-[17px] font-bold text-[#000000] mr-[120px] order-right-little-line">-->
                <!--                            {{ __('all_of_them') }}:-->
                <!--                        </div>-->
                <!--                        <div class="text-[#6C4BD8] text-[20px] font-semibold mr-[15px] w-[115px] block">-->
                <!--                            {{number_format (all_product_price, ' ', ' ')}} {{ __('som') }}-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                    <div v-if="checked" class="flex justify-end mt-[40px] self-center order-right-line">-->
                <!--                        <div class="text-[17px] font-bold text-[#000000] mr-[120px] order-right-little-line-a">-->
                <!--                            {{'BTS Pochta (Вес: ' + all_weight + ' kg)'}}-->
                <!--                        </div>-->
                <!--                        <div class="text-[#6C4BD8] text-[20px] font-semibold mr-[15px] w-[115px] block">-->
                <!--                            {{number_format (bts_postal_price, ' ', ' ') + ' ' + __('som')}}-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                    <div v-if="checked" class="flex justify-end mt-[40px] self-center order-right-line">-->
                <!--                        <div class="text-[17px] font-bold text-[#000000] mr-[120px] order-right-little-line-b">-->
                <!--                            {{ __('all_of_them') + '(Xizmatlar bilan)' }}:-->
                <!--                        </div>-->
                <!--                        <div class="text-[#6C4BD8] text-[20px] font-semibold mr-[15px] w-[115px] block">-->
                <!--                            {{number_format (all_price, ' ', ' ')}} {{ __('som') }}-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->

                <br/>
                <div class="" style="margin-top: 50px">
                    <button @click="confirmOrder" type="submit" :disabled="isDisabled"
                            class="uk-navbar-right px-[20px] py-[12px] rounded-[7px] bg-[#6C4BD8] text-[#FFFFFF] text-[16px] font-medium leading-[19px] transition-style">
                        <img v-if="isLoading" class="img_lading"
                             :src="'https://upload.wikimedia.org/wikipedia/commons/a/ad/YouTube_loading_symbol_3_%28transparent%29.gif'"/>
                        <span class="">{{ __("order_confirmation") }}</span>
                    </button>
                </div>
            </div>

            <vue-basic-alert
                    :duration="300"
                    :closeIn="3000"
                    ref="alert"/>
        </form>
    </div>
</template>

<script>
    import axios from "axios";
    import Select2 from 'vue3-select2-component';
    import VueBasicAlert from 'vue-basic-alert'
    import HeaderCheck from "@/components/Block/HeaderCheck";
    import {apiUrls} from "@/utils/const/apiUrls";
    import mixin from "@/boot/boot-mixin";
    import VueMask from '@devindex/vue-mask';
    import Select from "@/components/Layouts/Select"; // <-- ADD THIS LINE

    export default {
        name: "OrderConfirmation",
        // eslint-disable-next-line vue/no-unused-components
        components: {HeaderCheck, VueBasicAlert, Select2, VueMask, Select},
        mixins: [mixin],
        data() {
            return {

                status: '',
                isLoading: false,
                isDisabled: false,
                isStyled: false,

                checked: false,
                likes: 1,
                items: [],
                all_price: 0,
                all_product_price: 0,
                bts_postal_price: 0,
                all_weight: 0,
                order_id: '',
                regions: [],
                areas: [],
                openCity: false,
                openRegion: false,
                date: null,
                tempPhone: String(this.$store.getters.getUser.phone).substring(String(this.$store.getters.getUser.phone).length - 9),
                beanForm: {
                    request_items: [],
                    fio: '',
                    address: '',
                    areas_id: null,
                    regions_id: null,
                    phone: '',
                    purpose: '',
                    users_id: this.$store.getters.getUser.id
                },
                form: {
                    phone: '',
                    areas_id: null,
                    regions_id: null,
                    first_name: String(this.$store.getters.getUser.fio),
                    last_name: '',
                    region: '',
                    city: '',
                    address: '',
                    purpose: '',
                    errors: {
                        phone: false,
                        first_name: false,
                        last_name: false,
                        region: false,
                        city: false,
                        address: false,
                        purpose: false
                    }
                },
                formPostal: {
                    senderCityId: 192, //M.Ulug`bek tumani kartografiya
                    receiverCityId: null,
                    weight: 0,
                    volume: 0,
                    senderDate: '',
                    senderDelivery: 1,
                    receiverDelivery: 1,
                },
                validation: {
                    phone: {
                        min: 9, //13
                        max: 9, //13
                        type: "number",
                        nullable: false
                    },
                    first_name: {
                        min: 0,
                        max: 255,
                        type: "string",
                        nullable: false
                    },
                    last_name: {
                        min: 0,
                        max: 255,
                        type: "string",
                        nullable: false
                    },
                    region: {
                        min: 0,
                        max: 255,
                        type: "string",
                        nullable: false
                    },
                    city: {
                        min: 0,
                        max: 255,
                        type: "string",
                        nullable: false
                    },
                    address: {
                        min: 0,
                        max: 255,
                        type: "string",
                        nullable: false
                    },
                    purpose: {
                        min: 0,
                        max: 255,
                        type: "string",
                        nullable: false
                    }
                },
            }
        },

        computed: {
            storeUser() {
                return this.$store.getters.getUser;
            }
        },
        methods: {
            regionSelectEvent({id}) {
                this.getAreasByRegionsId(Number(id))
                this.beanForm.regions_id = Number(id)
            },
            areaSelectEvent({id}) {
                this.formPostal.receiverCityId = Number(id)
                this.beanForm.areas_id = Number(id)
                // this.btsPostPrice()
            },

            getAreasByRegionsId(val) {
                axios.get(apiUrls.BTS_AREAS + "?" + this.setParams({regions_id: val}),{
                    headers: {
                        "Authorization": 'Bearer ' + this.storeUser.token
                    }
                })
                    .then(res => {
                        this.areas = res.data.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            },

            getRegions() {
                axios.get(apiUrls.BTS_REGIONS, {
                    headers: {
                        "Authorization": 'Bearer ' + this.storeUser.token
                    }
                })
                    .then(res => {
                        this.regions = res.data.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            },

            confirmOrder() {

                if (Object.keys(this.storeUser).length == 0) {
                    this.showWarningInfo('Iltimos avval OneId orqali avtorizatsiyadan o`ting');
                    return
                }

                this.form.phone = this.tempPhone;

                if (this.form.phone.length > this.validation.phone.max || this.form.phone.length < this.validation.phone.min) {
                    this.showErrorInfo('Telefon raqami kiritilishi shart!');
                    this.form.errors.phone = true;
                    return;
                }
                if (typeof this.form.first_name === this.validation.first_name.type && (this.form.first_name.length > this.validation.first_name.max || this.form.first_name.length <= this.validation.first_name.min)) {
                    this.showErrorInfo('F.I.O kiritilishi shart!');
                    this.form.errors.first_name = true;
                    return;
                }
                this.form.errors.first_name = false;
                if (this.form.areas_id == null) {
                    this.showErrorInfo('Viloyat va tuman kiritilishi shart!');
                    this.form.errors.city = true;
                    return;
                }
                this.form.errors.city = false;
                if (typeof this.form.address === this.validation.address.type && (this.form.address.length > this.validation.address.max || this.form.address.length <= this.validation.address.min)) {
                    this.showErrorInfo('Manzil kiritilishi shart!');
                    this.form.errors.address = true;
                    return;
                }
                this.form.errors.address = false;

                if (typeof this.form.purpose === this.validation.purpose.type && (this.form.purpose.length > this.validation.purpose.max || this.form.purpose.length <= this.validation.purpose.min)) {
                    this.showErrorInfo('Ariza mazmuni kiritilishi shart!');
                    this.form.errors.purpose = true;
                    return;
                }
                this.form.errors.purpose = false;

                // return this.form.errors.phone && this.form.errors.first_name && this.form.errors.city && this.form.errors.address;

                this.beanForm.request_items = []

                for (let i = 0; i < this.items.length; i++) {
                    this.beanForm.request_items.push({sources_id: this.items[i].id})
                }

                this.beanForm.fio = this.form.first_name
                this.beanForm.address = this.form.address
                this.beanForm.purpose = this.form.purpose
                this.beanForm.phone = this.form.phone

                this.isLoading = true
                this.isDisabled = true

                axios.post(apiUrls.REQUESTS + '/add-request' + "?" + this.setParams({'users_id': this.storeUser.id}), this.beanForm, {
                    headers: {
                        "Authorization": 'Bearer ' + this.storeUser.token
                    }, 
                },)
                    .then(res => {
                        this.isLoading = false
                        this.isDisabled = false
                        this.showInfo('Sizning buyurtmangiz qabul qilindi');
                        this.$store.commit('clearStoreItems');
                        console.log(res)

                        setTimeout(() => {
                            this.$router.push("/cabinet")
                        }, 2000)

                    }).catch(err => {
                    this.isLoading = false
                    this.isDisabled = false
                    this.showErrorInfo('Arizani yuborishda xatolik!');
                    console.log(err)
                })
            },

            itemListener() {
                this.allPrice()
                // this.btsPostPrice()
            },

            allPrice() {
                let data = this.getStoreMD();
                let sum_weight = 0;
                this.all_product_price = 0
                this.all_weight = 0
                for (let i = 0; i < data.length; i++) {
                    this.all_product_price += data[i].price * data[i].count
                    sum_weight += data[i].weight * data[i].count
                }
                this.formPostal.weight = sum_weight.toFixed(2);
                this.all_weight = sum_weight.toFixed(2)
                this.all_price = this.all_product_price
            },

            // btsPostPrice() {
            //     let current_date = new Date();
            //     let completeDate = current_date.getFullYear() + '-' + (current_date.getMonth() + 1 < 10 ? '0' : '') + (current_date.getMonth() + 1) + '-' + (current_date.getDate() + 1 < 10 ? '0' : '') + current_date.getDate();
            //     if (this.formPostal.receiverCityId == null) {
            //         this.showErrorInfo('Iltimos tumaningizni tanlang');
            //         return
            //     }
            //     this.formPostal.senderDate = completeDate
            //     this.bts_postal_price = 0
            //     axios.post(apiUrls.BTS + '/calculate', this.formPostal)
            //         .then(res => {
            //             this.bts_postal_price = res.data.summaryPrice;
            //             this.all_price = res.data.summaryPrice + this.all_product_price;
            //         }).catch(err => {
            //         console.log(err)
            //     })
            // },

            getStoreMD() {
                return this.$store.getters.getSIMetaData
            },

            tableFilterQuery(filter) {
                if (!filter) return '';
                let queryArray = [];
                Object.keys(filter).map((objectKey) => {
                    const value = filter[objectKey];
                    if (value === undefined || value === null || value.length === 0)
                        return;
                    queryArray.push(objectKey + '=' + value);
                });

                if (queryArray.length !== 0)
                    return '?' + queryArray.join('&');
                else return ""
            },

        },
        watch: {
            // checked: function (val) {
            //     if (val) {
            //         this.beanForm.is_with_shipping = 1
            //         // this.btsPostPrice()
            //     } else {
            //         this.beanForm.is_with_shipping = 0
            //         this.all_price = this.all_product_price
            //         this.bts_postal_price = 0
            //     }
            // },
            tempPhone: function (val) {
                if (val.length == 13) this.form.errors.phone = false;
            }
        },
        mounted() {
            this.getRegions()
            this.allPrice()
            this.items = this.getStoreMD()
            this.checked = false
            // this.beanForm.is_with_shipping = 0
        }

    }
</script>

<style scoped>

    .img_lading {
        width: 20px;
        height: 20px;
        float: left;
        margin-right: 10px;
    }


</style>